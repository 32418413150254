import { captureException, setUser, type BrowserOptions, type Request } from '@sentry/browser'

import { apiFetchWorkspaceless } from ':/_console/js/api/utils/apiFetch.ts'
import type { LoginData } from '~/features/workspaces/types'

import { Config } from '../../../config.ts'

const config = Config()

/**
 * Redact sensitive data from Sentry requests.
 *
 * This replaces the `authKey` query parameter in Sentry request URLs as well as the `referer`
 * header.
 */
function redactSensitiveData(request: Request | undefined): undefined {
  if (request?.url) {
    const url = new URL(request.url)
    if (url.searchParams.has('authKey')) {
      url.searchParams.set('authKey', 'XXXXXXXXXX')
      request.url = String(url)
    }
  }

  if (request?.headers) {
    // Since we’re filtering sensitive data here let’s include all keys, even non-own keys.
    // eslint-disable-next-line no-restricted-syntax
    for (const key in request.headers) {
      // HTTP 1 keys are case insensitive. HTTP 2 keys are often cased incorrectly.
      // Let’s assume the keys are case insensitive.
      if (key.toLowerCase() !== 'referer') {
        continue
      }

      const referer = new URL(request.headers[key])
      if (referer.searchParams.has('authKey')) {
        referer.searchParams.set('authKey', 'XXXXXXXXXX')
        request.headers[key] = String(referer)
      }
    }
  }
}

export default {
  dsn: 'https://eff59deab1e34edd9c83cc672cdbf706@o510507.ingest.sentry.io/5606278',
  release: process.env.GITHUB_RUN_ID,
  environment: config.env,

  // Sample all transactions for now. With a budget of 100K sampled transactions per month,
  // and an average of 42K pageviews per month, we should stay under the budget. We can adjust this down if needed.
  tracesSampleRate: 1,
  attachStacktrace: true,

  beforeSendTransaction(event) {
    redactSensitiveData(event.request)
    return event
  },

  beforeSend(event) {
    redactSensitiveData(event.request)

    // Well known webcrawlers are ignored via Inbound Data Filters, and they do not count towards our plan quotas, see:
    // - https://docs.sentry.io/product/data-management-settings/filtering/#inbound-data-filters
    // - https://sentry.io/settings/transloadit/projects/transloadit/filters/data-filters/
    if (config.env === 'development') {
      return null
    }

    if (event.message === 'Ignoring Event: exclusion rule') {
      // This is a message from the Plausible tracking script that we don't want to send to Sentry.
      return null
    }

    if (event.message?.startsWith('[Uppy]')) {
      // Uppy is chatty, don't send its messages to Sentry
      return null
    }

    return event
  },
} satisfies BrowserOptions

export async function addUserToSentry() {
  try {
    // this API route won't return a redirect and so we can safely pass a noop navigate function here
    const res = await apiFetchWorkspaceless('/users/login_data.json', { method: 'GET' })
    if (res?.data?.login_data) {
      const loginData: LoginData = res.data.login_data
      if (loginData.loggedIn) {
        setUser({ id: loginData.id, email: loginData.email })
      }
    }
  } catch (error) {
    captureException(error)
  }
}
