interface RequestSpe {
  status: 'request'
  payload: null
  error: null
}
interface SuccessSpe<T> {
  status: 'success'
  payload: T
  error: null
}
interface FailureSpe {
  status: 'failure'
  payload: null
  error: string
}
type EmptySpe = Record<string, never>

type SpeCreatorReturn<T> = RequestSpe | SuccessSpe<T> | FailureSpe | EmptySpe

export class SpeCreator {
  static request(): RequestSpe {
    return { status: 'request', payload: null, error: null }
  }

  static success<T>(response: T): SuccessSpe<T> {
    return { status: 'success', payload: response, error: null }
  }

  static failure(message: string): FailureSpe {
    return { status: 'failure', payload: null, error: message }
  }

  static empty(): EmptySpe {
    return {}
  }
}

export type { RequestSpe, SuccessSpe, FailureSpe, EmptySpe, SpeCreatorReturn }
