import Cookies from 'js-cookie'

import { handleJSONResponse as handleJsonResponse } from './handleResponse.ts'
import { objectToFormData } from './objectToFormData.ts'
import { Config } from '../../../../config.ts'
import type { SpeCreatorReturn } from '../../utils/SpeCreator.ts'
import { SpeCreator } from '../../utils/SpeCreator.ts'
import Url from '../../utils/Url.ts'
import { api2 } from '../../utils/Urls.ts'

const config = Config()

interface BaseFetchApiOptions {
  /** If the URL is app-specific (the default), the API request will be scoped to the currently active app slug. */
  isWorkspaceSpecific?: boolean
  /** An optional custom handler for the response. */
  handleResponse?: (response: Response) => Promise<$TSFixMe>
  signal?: AbortSignal
}

type GetDeleteApiFetchOptions = BaseFetchApiOptions & {
  method?: 'GET' | 'DELETE'
  /** should be an object (for POST and PUT requests), or not passed (for GET requests) */
  body?: never
}

type PostPutApiFetchOptions<PostBody extends Record<PropertyKey, unknown>> = BaseFetchApiOptions & {
  method?: 'POST' | 'PUT'
  body?: PostBody
}

type ApiFetchOptions<PostBody extends Record<PropertyKey, unknown>> =
  | GetDeleteApiFetchOptions
  | PostPutApiFetchOptions<PostBody>

/**
 * Send a request to the PHP API.
 */
export function apiFetch<PostBody extends Record<PropertyKey, unknown>>(
  url: string,
  {
    body,
    method = 'GET',
    isWorkspaceSpecific = true,
    handleResponse = handleJsonResponse,
    signal,
  }: ApiFetchOptions<PostBody> = {},
) {
  const sluggedUrl = isWorkspaceSpecific ? Url.slugged(url) : url

  const headers: [string, string][] = [['Accept', 'application/json']]
  const csrfToken = Cookies.get('csrfToken2') ?? Cookies.get('csrfToken')
  if (csrfToken) headers.push(['X-CSRF-Token', csrfToken])

  return (
    fetch(config.webapiEndpoint + sluggedUrl, {
      method,
      mode: 'cors',
      credentials: 'include',
      body: body ? objectToFormData(body) : undefined,
      headers: new Headers(headers),
      signal,
    })
      // Catchable errors! (only network errors, and CORS errors)
      // Must be before .then(), otherwise all validation errors will get
      // swallowed by the generic 'Smth went wrong'  message.
      .catch((cause) => {
        if (cause instanceof TypeError && cause.message === 'Failed to fetch') {
          throw new Error(
            `Network error while doing a ${method} request to ${sluggedUrl}. This usually means the server is unreachable or there's no internet connection.`,
            { cause },
          )
        }
        throw new Error(`Request error for ${method} ${sluggedUrl}: ${cause.message}`, { cause })
      })
      // Thenable errors! (includes all statuses returned from the server)
      .then(handleResponse)
  )
}

export function apiFetchWorkspaceless<PostBody extends Record<PropertyKey, unknown>>(
  url: string,
  options?: ApiFetchOptions<PostBody>,
) {
  return apiFetch(url, { ...options, isWorkspaceSpecific: false })
}

/**
 * Send a request directly to API2.
 * @param {string} url
 * @param {object} [options]
 * @param {string} [options.method]
 * @param {string} [options.service] - Optionally specify a full API2 service URL,
 *     eg. "https://api2-jakarta.transloadit.com", to target a specific instance.
 */
export function apiFetchApi2(url: string, { method = 'GET', service = api2() } = {}) {
  return (
    fetch(new URL(url, service), {
      method,
      mode: 'cors',
      credentials: 'omit',
      headers: { accept: 'application/json' },
    })
      // Catchable errors! (only network errors, and CORS errors)
      // Must be before .then(), otherwise all validation errors will get
      // swallowed by the generic 'Smth went wrong'  message.
      .catch((cause) => {
        if (cause instanceof TypeError && cause.message === 'Failed to fetch') {
          throw new Error(
            `Network error while fetching ${url}. This usually means the server is unreachable or there's no internet connection.`,
            { cause },
          )
        }
        throw new Error(`Request error for ${method} ${url}: ${cause.message}`, { cause })
      })
      .then((response) => response.json())
  )
}

export type Dispatch<T> = (spe: SpeCreatorReturn<T>) => void

function speWrapper<T>(dispatch: Dispatch<T>, fetchPromise: Promise<T>) {
  if (dispatch) dispatch(SpeCreator.request())
  return fetchPromise
    .then((response) => {
      if (dispatch) dispatch(SpeCreator.success<T>(response))
      return Promise.resolve(response)
    })
    .catch((error: Error) => {
      if (dispatch) dispatch(SpeCreator.failure(error.message))
      return Promise.reject(error)
    })
}

export function speFetch<T, B extends Record<PropertyKey, unknown>>(
  dispatch: Dispatch<T>,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  body?: Record<PropertyKey, unknown>,
  options?: Exclude<ApiFetchOptions<B>, 'isWorkspaceSpecific' | 'body' | 'method'>,
) {
  return speWrapper(dispatch, apiFetch(url, { ...options, body, method } as ApiFetchOptions<B>))
}
export function speFetchWorkspaceless<T, B extends Record<PropertyKey, unknown>>(
  dispatch: Dispatch<T>,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  body?: B,
  options?: Exclude<ApiFetchOptions<B>, 'isWorkspaceSpecific' | 'body' | 'method'>,
) {
  return speWrapper(
    dispatch,
    apiFetchWorkspaceless(url, { ...options, method, body } as ApiFetchOptions<B>),
  )
}
export function speFetchApi2<T>(
  dispatch: Dispatch<T>,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  options?: { service?: string },
) {
  return speWrapper(dispatch, apiFetchApi2(url, { ...options, method }))
}
