import type { Download } from './types.ts'

export const MINIMUM_FRACTION_DIGITS = 10
export const INTERNET_SPEEDS = [
  {
    label: 'Basic DSL internet, slow and outdated',
    value: 1.5,
  },
  {
    label: '3G internet, slow mobile internet',
    value: 5,
  },
  {
    label: 'Basic broadband speed, light usage and small households',
    value: 10,
  },
  {
    label:
      'Standard broadband speed, moderate usage and small to medium households. 4G mobile internet',
    value: 25,
  },
  {
    label: 'Fast broadband speed, heavy internet users and larger households',
    value: 100,
  },
  {
    label: 'Very fast broadband, multiple users streaming 4K content simultaneously',
    value: 300,
  },
  {
    label:
      'Gigabit internet, top-tier speed for power users and large households with many connected devices',
    value: 1000,
  },
]

export const PREVIEW_STRATEGIES = {
  audio: ['artwork', 'waveform', 'icon'],
  video: [/* 'clip', */ 'artwork', 'frame', 'icon'],
  document: ['page', 'icon'],
  image: ['image', 'icon'],
  webpage: ['render', 'icon'],
  archive: ['icon'],
  unknown: ['icon'],
}
export const BASELINE_LATENCY_MS = 20
export const DEFAULT_S3_EGRESS_PRICE_PER_GB = 0.09
export const DEFAULT_CF_EGRESS_PRICE_PER_GB = 0.085
export const DEFAULT_NUMBER_OF_ASSETS = 20000
export const DEFAULT_TRANSLOADIT_PLAN = 'medium-business'
export const DEFAULT_REQUESTS_PER_ASSET_PER_MONTH = 100
export const DEFAULT_PREVIEW_GENERATION_RATIO = 0.15
export const DEFAULT_WIDTH = 300
export const DEFAULT_HEIGHT = 200
export const DEFAULT_MBITS = 25
export const DEFAULT_VIDEO_STRATEGY = 'frame'
export const DEFAULT_FORMAT = 'png'
export const DEFAULT_VERSION = 200
export const DEFAULT_DOWNLOAD: Download = {
  cdnSize: 0,
  cdnStarted: 0,
  cdnTime: 0,
  convertSize: 0,
  convertStarted: 0,
  convertTime: 0,
  originalSize: 0,
  originalTime: 0,
  isLoading: 'None',
  assembly: undefined,
  error: '',
}
export const DEFAULT_RESIZE_STRATEGY = 'min_fit'
