import Cookies from 'js-cookie'
import mitt from 'mitt'

import { BASELINE_LATENCY_MS } from '{src}/javascripts/file-preview-demo/constants.ts'

import { FLASH_MESSAGES_COOKIE } from './constants.ts'
import type { CookieFlashMessage, FlashMessage } from './types'

export const emitter = mitt()

const fromCookieMessage = (message: CookieFlashMessage): FlashMessage => ({
  isError: message.type === 'error',
  text: message.text.replaceAll('+', ' '),
})

const toCookieMessage = (message: FlashMessage): CookieFlashMessage => ({
  type: message.isError ? 'error' : 'info',
  text: message.text,
})

export const readFlashMessages = () => {
  let json: CookieFlashMessage[]

  try {
    const str = Cookies.get(FLASH_MESSAGES_COOKIE)

    if (!str) {
      json = []
    } else {
      json = JSON.parse(str)
    }
  } catch (err) {
    console.error('Failed to parse flash messages cookie', err)
    json = []
  }

  const messages = json.map(fromCookieMessage)

  return messages
}

export const createFlashMessage = (message: FlashMessage) => {
  const messages = readFlashMessages()

  // don't add the same message twice
  if (messages.find((m) => m.text === message.text)) {
    return
  }

  messages.push(message)
  Cookies.set(FLASH_MESSAGES_COOKIE, JSON.stringify(messages.map(toCookieMessage)))
  emitter.emit('flash_message')
}

export const deleteFlashMessages = () => {
  if (Cookies.get(FLASH_MESSAGES_COOKIE)) {
    Cookies.remove(FLASH_MESSAGES_COOKIE)
  }
}
export const calculateDownloadTime = (size: number, mbits: number) => {
  const originalTime = BASELINE_LATENCY_MS + ((size * 8) / (mbits * 1000 * 1000)) * 1000
  if (Number.isNaN(originalTime)) return 0
  if (originalTime < 0) return 0
  return originalTime
}
