// <Disclaimer>
//   I know that it feels like this file can be refactored into something more simple,
//   - but it largely can't be!
//   There truly is a complex process here, with multiple sources-of-truth (url, localStorage, currentSlug from backend, memberships from backend!) & async events (on-page-enter, on-account-fetched, on-app-select).
// </Disclaimer>.
//
// This code is concerned with managing /:workspace-slug/-s.
// The cool things are:
// - it allows to onAppSelect() without refreshing the page, - and it will keep your existing url.
// - it allows to open two different apps in two different tabs.
// - it redirects you to the latest-used-appName if you access /c/templates e.g.
//
// Examples of what should work:
// 1. If we rename the app `nervelandia -> hi`, and then try to access `nervelandia` -
//    we get redirected to `hi`.
// 2. If we try to access `/c/nonexisent/templates` -
//    we get redirected to `/c/:smth-real/templates`.
// 3. If we try to access `/c/templates` -
//    we get redirected to `/c/hi/templates`.

// We use this as a variable everywhere, except for this very file,
// because it reads better as a string than as a variable.
const prefix = 'c'

const urlsThatShouldIncludeWorkspaces = [
  '/c/assemblies',
  '/c/settings',
  '/c/template-credentials',
  '/c/collaborators',
  '/c/billing',
  '/c/templates',
  '/c/addons',
]
const nonWorkspaceSpecificUrls = [
  '/c/user/settings',
  '/c/auth-keys/view',
  '/c/create-new-workspace',
  '/c/admin',
  '/c/login',
  '/admin',
]

const urlStartsWith = (url: string, keyword: string) => {
  return url === keyword || url.startsWith(`${keyword}/`)
}

// TODO consider using react routing for this? With props appless for each <Route/>?
//
// _isWorkspacelessUrl('/c/assemblies') //=> true
// _isWorkspacelessUrl('/c/facebook/assemblies') //=> false
export const isWorkspacelessUrl = (url: string) => {
  return !(
    urlsThatShouldIncludeWorkspaces.some((keyword) => urlStartsWith(url, keyword)) ||
    url === '/c' ||
    url === '/c/'
  )
}

const isWorkspaceSpecificUrl = (url: string) => {
  return !nonWorkspaceSpecificUrls.some((n) => urlStartsWith(url, n))
}

const _getWorkspaceSlugFromUrl = (url: string) => {
  if (!isWorkspaceSpecificUrl(url) || !isWorkspacelessUrl(url)) {
    return null
  }
  const segments = url.split('/')
  return segments[1] === prefix ? segments[2] : segments[1]
}

const getWorkspaceSlugFromLocalStorage = () => {
  return localStorage.getItem('currentWorkspaceSlug')
}

// This is used in <a> hrefs, and as the initial App.js key.
// We do not treat LS as the source of truth, because we want to enable different-apps-in-different-tabs system.
const getInitialSlug = () => {
  const workspaceSlug =
    _getWorkspaceSlugFromUrl(window.location.pathname) ?? getWorkspaceSlugFromLocalStorage()
  return workspaceSlug
}

// Use as: '/c' + {slugged('/templates')}`
const slugged = (url: string) => {
  // Trying to find
  const workspaceSlug = getInitialSlug()
  return workspaceSlug ? `/${workspaceSlug}${url}` : url
}

export default {
  getWorkspaceSlugFromLocalStorage,
  slugged,
  isWorkspaceSpecificUrl,
}
