// Warning: This file is included by CLI scripts, Eleventy, and Browsers.
// Don't use any Node.js specific APIs here. Don't import any Node.js modules.

export type Modes = 'normal' | 'watch' | '*'
export type Envs = 'development' | 'staging' | 'production' // | '*'

export interface TLConfig {
  allRegions: string[]
  api2Endpoint: string
  assetBuildPath: string
  collections: string[]
  companionEndpoint: string
  eleventyCert: { cert: string; key: string }
  env: Envs
  isInCI: boolean
  isInTest: boolean
  mode: Modes
  outDir: string
  subset: string
  targetRegions: string[]
  transformIncludeAllowlist: string[]
  serveurEndpoint: string
  webapiEndpoint: string
}

export function Config(): TLConfig {
  const devHosts = ['localhost', 'transloadit.dev', 'www-vbox.transloadit.com']
  const stagingHosts = [
    'crm-buildnode.transloadit.com',
    'tmpweb-buildnode.transloadit.com',
    'webstaging.transloadit.website',
    'www-buildnode.transloadit.com',
    'transloadit.website',
  ]

  const isNode =
    typeof process !== 'undefined' &&
    typeof process?.argv !== 'undefined' &&
    process?.release?.name?.search(/node|io\.js/) !== -1

  let hostname = ''
  if (!isNode && typeof window !== 'undefined') {
    hostname = window.location.hostname
  }

  let env: Envs
  let isInTest = false

  if (process.env.NODE_ENV === 'production') {
    env = 'production'
  } else if (process.env.NODE_ENV === 'development') {
    env = 'development'
  } else if (process.env.NODE_ENV === 'test') {
    isInTest = true
    env = 'development'
  } else if (process.env.NODE_ENV) {
    throw new Error(`Invalid environment: ${process.env.NODE_ENV}`)
  } else {
    env = 'development'
  }

  const isInCI = ['1', 'true'].includes(process.env.CI || '')

  // We build a production build in CI, so with NODE_ENV=production, but
  // inside CI we still want different Sentry behavior, etc:
  if (hostname) {
    if (devHosts.includes(hostname)) {
      env = 'development'
    }
    if (stagingHosts.includes(hostname)) {
      env = 'staging'
    }
  }

  let mode: Modes = 'normal'
  if (isNode) {
    if (
      env === 'development' &&
      (process.argv.includes('--watch') || process.argv.includes('--serve'))
    ) {
      mode = 'watch'
    }
  }

  let api2Endpoint = process.env.API2_ENDPOINT ?? ''
  if (!api2Endpoint) {
    if (env === 'production') {
      api2Endpoint = 'https://api2.transloadit.com'
    } else {
      api2Endpoint = 'https://api2-vbox.transloadit.com'
    }
  }

  const companionEndpoint = `${api2Endpoint}/companion`

  let webapiEndpoint: string = process.env.WEBAPI_ENDPOINT ?? ''
  if (!webapiEndpoint) {
    if (env === 'development') {
      webapiEndpoint = 'https://webapi.transloadit.dev'
    } else if (env === 'staging') {
      webapiEndpoint = 'https://webapi.transloadit.website'
    } else {
      webapiEndpoint = 'https://webapi.transloadit.com'
    }
  }

  let serveurEndpoint: string = process.env.SERVEUR_ENDPOINT ?? ''
  if (!serveurEndpoint) {
    let proto: 'http' | 'https' = 'http'
    let domain = 'transloadit.dev'
    let port = 8111

    if (webapiEndpoint.includes('https://')) {
      proto = 'https'
    }
    if (webapiEndpoint.includes('transloadit.dev')) {
      if (isInCI) {
        // @TODO(Amarok) In CI we should bootstrap Nomad and run Serveur. Currently we use Eleventy's built-in server
        // to test what is inside _site, but that comes with the risk of diverging headers, redirects, etc.
        proto = 'https'
        domain = '127.0.0.1'
        port = 3000
      } else {
        domain = 'transloadit.dev'
        port = 8111
      }
    } else if (webapiEndpoint.includes('transloadit.com')) {
      if (env === 'production') {
        domain = 'transloadit.com'
      } else {
        domain = 'branch-local.transloadit.com'
      }
    }

    serveurEndpoint = `${proto}://${domain}${port === 443 || port === 80 ? '' : `:${port}`}`
  }

  const assetBuildPath = `/assets/build/`

  // To speed up the build, we only try to use transforms to include things in these callsites:
  const transformIncludeAllowlist = [
    'docs/supported-formats/audio-codecs/index.html',
    'docs/supported-formats/audio-video-filters/index.html',
    'docs/supported-formats/audio-video-formats/index.html',
    'docs/supported-formats/fonts/index.html',
    'docs/supported-formats/image-formats/index.html',
    'docs/supported-formats/video-codecs/index.html',
    'docs/supported-formats/video-codecs/index.html',
    'docs/transcoding/audio-encoding/audio-presets/index.html',
    'docs/transcoding/video-encoding/video-presets/index.html',
    'love/index.html',
  ]

  // The Let's Encrypt gha cron in the api repo refreshes SSL certs for
  // .dev, .website, and .com. You can import the .dev certs like so:
  // rsync -a ~/code/api2/envs/ssl/certificates/./_.transloadit.dev.* ~/code/content/_eleventy/./
  let cert = `_eleventy/./_.transloadit.dev.crt`
  let key = `_eleventy/./_.transloadit.dev.key`
  if (process.env.API2_ENDPOINT === 'https://api2.transloadit.com') {
    // ! We explicitly DO NOT WANT TO rsync the .com SSL certs into this content repo
    // ! Because we are quite liberal with sharing it with external contributors
    // ! and we don't want to expose our .com SSL certs to them
    // ! as they could be used to MITM our production endpoints
    cert = `../api2/envs/ssl/certificates/./_.transloadit.com.crt`
    key = `../api2/envs/ssl/certificates/./_.transloadit.com.key`
  }
  const devCert = { cert, key }

  const outDir = '_site'

  const subset = ['1', 'true'].includes(process.env.SUBSET || '')
    ? 'mini'
    : process.env.SUBSET || 'full'

  const collections = [
    'accelerators',
    'botmails',
    'contracts',
    'ctas',
    'demos',
    'devices',
    'docgstarted',
    'doctopics',
    'documents',
    'endpoints',
    'entities',
    'faq',
    'formats',
    'legal',
    'milestones',
    'newsletters',
    'osprojects',
    'pages',
    'posts',
    'premium-packages',
    'robots',
    'sdks',
    'services',
    'teammates',
    'unpublished',

    // We want to render the same Service templates both as a marketing page under
    // - /services/$service/ as well as
    // - /docs/transcoding/$service/
    // I found no way that Eleventy can do this. The blocker being that a layout cannot be adjusted at elventyComputed time.
    'generated_docservices',

    // We want to render the same Robot templates both as a marketing page under
    // - /services/$service/$robot/ as well as
    // - /docs/transcoding/$service/$robot/
    // I found no way that Eleventy can do this. The blocker being that a layout cannot be adjusted at elventyComputed time.
    'generated_docrobots',
  ]

  const productionRegions = ['us-east-1', 'eu-west-1', 'ap-southeast-1']
  const localRegions = ['vbox']
  const allRegions = [...productionRegions, ...localRegions]
  const targetRegions =
    env === 'production' || api2Endpoint === 'https://api2.transloadit.com'
      ? productionRegions
      : localRegions

  const tlConfig: TLConfig = {
    allRegions,
    api2Endpoint,
    assetBuildPath,
    collections,
    companionEndpoint,
    eleventyCert: devCert,
    env,
    isInCI,
    isInTest,
    mode,
    outDir,
    subset,
    targetRegions,
    transformIncludeAllowlist,
    serveurEndpoint,
    webapiEndpoint,
  }

  return tlConfig
}
