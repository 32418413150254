import { abbr } from '@transloadit/abbr'

import { createFlashMessage } from '~/features/flash-messages/utils.ts'

export function isHTML(str: string) {
  const htmlRegex = /<[^>]*>/
  return htmlRegex.test(str)
}

// This will need to be updated with more relevant information later, when we know what our api tends to return
export const handleJSONResponse = (response: Response) => {
  return (
    response
      .text()
      // If we can't parse this response's json
      .catch((cause) => {
        throw new Error(`Something went wrong. Could not get URL: ${response.url}.`, {
          cause,
        })
      })
      // If we can parse this response's json
      // There's some slightly terrifying error management - we'll change it when we apiify backend some more.
      .then((text) => {
        let res
        try {
          res = JSON.parse(text)
        } catch (err) {
          // print CakePHP's debug output if we're in development
          if (process.env.NODE_ENV === 'development' && isHTML(text)) {
            const el = document.getElementById('debug')
            if (el) {
              el.innerHTML = text
            }
          }

          console.error(`Full value that we could not JSON-parse`, text)

          throw new Error(
            `Something went wrong. Could not parse JSON from URL: ${response.url}. Snippet: '${abbr(
              text,
              200,
            )}'. ${err}`,
            {
              cause: err,
            },
          )
        }

        const { meta } = res

        // if we hit the rate limiter, redirect the user to the 429 page
        if (response.status === 429 && window.location.pathname !== '/errors/429/') {
          createFlashMessage({ text: meta.message, isError: meta.status === 'error' })
          window.location.href = '/errors/429/'
        }

        // Sometimes we get error messages in the form of { message, url, code } 💀
        if (res.code === 500 || res.code === 404) {
          throw new Error(res.message)
        }

        // More traditional errors & validations
        if ((typeof res === 'object' && !meta && res.message) || res.error) {
          throw new Error(res.message || res.error)
        }

        // And more traditional errors & validations
        if (meta.status === 'error') {
          const errorMessage = meta.message
          throw new Error(errorMessage)
        }

        // No errors!
        return res
      })
  )
}

export async function handlePDFResponse(response: Response) {
  if (response.ok && response.headers.get('Content-Type') === 'application/pdf') {
    return response.blob()
  }

  const text = await response.text()

  let res
  try {
    res = JSON.parse(text)
  } catch (err) {
    // print CakePHP's debug output if we're in development
    if (process.env.NODE_ENV === 'development' && isHTML(text)) {
      const el = document.getElementById('debug')
      if (el) {
        el.innerHTML = text
      }
    }

    console.error(`Full value that we could not JSON-parse`, text)

    throw new Error(
      `Something went wrong. Could not parse JSON from URL: ${response.url}. Snippet: '${abbr(
        text,
        200,
      )}'. ${err}`,
      { cause: err },
    )
  }

  const { meta } = res

  // Sometimes we get error messages in the form of { message, url, code } 💀
  if (res.code === 500 || res.code === 404) {
    throw new Error(res.message)
  }

  // More traditional errors & validations
  if ((typeof res === 'object' && !meta && res.message) || res.error) {
    throw new Error(res.message || res.error)
  }

  // And more traditional errors & validations
  if (meta.status === 'error') {
    const errorMessage = meta.message
    throw new Error(errorMessage)
  }

  return res
}
